import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { createContext } from "radix-vue";
import {
  MaybeRefOrGetter,
  onScopeDispose,
  onWatcherCleanup,
  Ref,
  shallowRef,
  toValue,
  watchEffect,
} from "vue";

const [inject, provide] = createContext<{
  key: symbol;
  axis: "vertical" | "horizontal";
}>("ReorderableList");

type DropItem = {
  key: symbol;
  index: number;
};

const createDropItem = (key: symbol, index: number): DropItem => {
  return { key, index };
};

const isDropItem = (
  item: Record<symbol | string, unknown>,
  key: symbol
): item is DropItem => {
  return item.key === key;
};

export interface ReorderableListOptions {
  axis?: "vertical" | "horizontal";
}

export const useReorderableList = <T>(
  list: Ref<T[]>,
  { axis = "vertical" }: ReorderableListOptions = {}
) => {
  const key = Symbol("list-dnd");

  provide({ key, axis });

  onScopeDispose(
    monitorForElements({
      canMonitor({ source }) {
        return isDropItem(source.data, key);
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;
        if (!isDropItem(sourceData, key) || !isDropItem(targetData, key)) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData);
        const startIndex = sourceData.index;
        const indexOfTarget = targetData.index;

        const finishIndex = getReorderDestinationIndex({
          startIndex,
          indexOfTarget,
          closestEdgeOfTarget,
          axis,
        });

        // no change.
        if (finishIndex === startIndex) {
          return;
        }

        list.value = reorder({
          list: list.value,
          startIndex,
          finishIndex,
        });
      },
    })
  );
};

type DragState = "idle" | "dragging" | "dragging-over";

export interface UseReorderableListItemProps {
  element: MaybeRefOrGetter<HTMLElement | null | undefined>;
  handle?: MaybeRefOrGetter<HTMLElement | null | undefined>;
  index: () => number;
}

export interface UseReorderableListItemReturn {
  readonly state: DragState;
  readonly edge: Edge | null;
}

const INITIAL_ITEM_STATE: UseReorderableListItemReturn = Object.freeze({
  state: "idle",
  edge: null,
});

export const useReorderableListItem = (
  props: UseReorderableListItemProps
): UseReorderableListItemReturn => {
  const context = inject(null);
  if (context === null) {
    return INITIAL_ITEM_STATE;
  }

  const key = context.key;

  const state = shallowRef<DragState>("idle");
  const edge = shallowRef<Edge | null>(null);

  watchEffect(() => {
    const element = toValue(props.element);
    const dragHandle = toValue(props.handle);
    const index = toValue(props.index);

    if (element == null || index === -1) {
      return;
    }

    const data = createDropItem(key, index);

    onWatcherCleanup(() => {
      state.value = "idle";
      edge.value = null;
    });

    onWatcherCleanup(
      draggable({
        element: element,
        dragHandle: dragHandle ?? undefined,
        getInitialData() {
          return data;
        },
        onDragStart() {
          state.value = "dragging";
        },
        onDrop() {
          state.value = "idle";
        },
      })
    );

    onWatcherCleanup(
      dropTargetForElements({
        element: element,
        canDrop({ source }) {
          return isDropItem(source.data, key);
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges:
              context.axis === "vertical"
                ? ["top", "bottom"]
                : ["left", "right"],
          });
        },
        onDrag({ self, source }) {
          if (source.element === element) {
            edge.value = null;
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = (source.data as DropItem).index;
          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource &&
              (closestEdge === "bottom" || closestEdge === "right")) ||
            (isItemAfterSource &&
              (closestEdge === "top" || closestEdge === "left"));

          edge.value = !isDropIndicatorHidden ? closestEdge : null;
        },
        onDragLeave() {
          edge.value = null;
        },
        onDrop() {
          edge.value = null;
        },
      })
    );
  });

  return {
    get state(): DragState {
      return state.value;
    },
    get edge(): Edge | null {
      return edge.value;
    },
  };
};

export const useManualReorderable = (source: Ref<any[]>) => {
  const reorder = (direction: 1 | -1, index: number) => {
    const items = source.value;
    const newIndex = index + direction;

    if (newIndex < 0 || newIndex >= items.length) {
      return;
    }

    const next = [...items];
    [next[newIndex], next[index]] = [next[index], next[newIndex]];

    source.value = next;
  };

  return { reorder };
};
