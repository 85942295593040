<template>
  <div
    v-if="edge !== null"
    :class="[
      'pointer-events-none absolute z-10 bg-blue-500',
      orientationStyles[orientation],
    ]"
    :style="{
      [edge]: `calc(-0.5 * (${gap} + ${thickness}))`,
      [orientation === 'horizontal' ? 'height' : 'width']: thickness,
    }"
  ></div>
</template>

<script setup lang="ts">
import type { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/types";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    edge: Edge | null;
    gap?: string;
    thickness?: string;
  }>(),
  {
    gap: "0px",
    thickness: "3px",
  }
);

type Orientation = "horizontal" | "vertical";

const edgeToOrientation: Record<Edge, Orientation> = {
  top: "horizontal",
  bottom: "horizontal",
  left: "vertical",
  right: "vertical",
};

const orientationStyles: Record<Orientation, string> = {
  horizontal: "left-0 right-0",
  vertical: "top-0 bottom-0",
};

const orientation = computed(() => {
  if (props.edge === null) {
    // get eslint to not complain about null lol
    return "horizontal";
  }

  return edgeToOrientation[props.edge];
});
</script>
